import indicaCG from "../assets/img/icons/IndicaCG.png";
import sativaCG from "../assets/img/icons/SativaCG.png";
import hybridCG from "../assets/img/icons/HybridCG.png";
import sativaDomCG from "../assets/img/icons/SativaDomCG.png";
import indicaDomCG from "../assets/img/icons/IndicaDomCG.png";

import indicaCF from "../assets/img/icons/IndicaCF.png";
import sativaCF from "../assets/img/icons/SativaCF.png";
import hybridCF from "../assets/img/icons/HybridCF.png";
import sativaDomCF from "../assets/img/icons/SativaDomCF.png";
import indicaDomCF from "../assets/img/icons/IndicaDomCF.png";

import indicaCMY from "../assets/img/icons/IndicaCMY.png";
import sativaCMY from "../assets/img/icons/SativaCMY.png";
import hybridCMY from "../assets/img/icons/HybridCMY.png";
import sativaDomCMY from "../assets/img/icons/SativaDomCMY.png";
import indicaDomCMY from "../assets/img/icons/IndicaDomCMY.png";

import indicaKeystone from "../assets/img/icons/IndicaKeystone.png";
import sativaKeystone from "../assets/img/icons/SativaKeystone.png";
import hybridKeystone from "../assets/img/icons/HybridKeystone.png";
import cbdKeystone from "../assets/img/icons/CBDKeystone.png";

import indicaPhenos from "../assets/img/icons/IndicaPhenos.png";
import sativaPhenos from "../assets/img/icons/SativaPhenos.png";
import hybridPhenos from "../assets/img/icons/HybridPhenos.png";
import sativaDomPhenos from "../assets/img/icons/SativaDomPhenos.png";
import indicaDomPhenos from "../assets/img/icons/IndicaDomPhenos.png";
import cbdPhenos from "../assets/img/icons/CBDPhenos.png";
import cbnPhenos from "../assets/img/icons/CBNPhenos.png";
import cbgPhenos from "../assets/img/icons/CBGPhenos.png";
import delta8Phenos from "../assets/img/icons/Delta-8Phenos.png";

import indicaPhenos2 from "../assets/img/icons/IndicaPhenos-2.png";
import sativaPhenos2 from "../assets/img/icons/SativaPhenos-2.png";
import hybridPhenos2 from "../assets/img/icons/HybridPhenos-2.png";
import sativaDomPhenos2 from "../assets/img/icons/SativaDomPhenos-2.png";
import indicaDomPhenos2 from "../assets/img/icons/IndicaDomPhenos-2.png";

import sativaCannabisConnect from "../assets/img/icons/Cannabis-Connection-Sativa.png";
import indicaCannabisConnect from "../assets/img/icons/Cannabis-Connection-Indica.png";
import hybridCannabisConnect from "../assets/img/icons/Cannabis-Connection-Hybrid.png";
import indicaHybridCannabisConnect from "../assets/img/icons/Cannabis-Connection-Ind-Hy.png";
import sativaHybridCannabisConnect from "../assets/img/icons/Cannabis-Connection-Sat-Hy.png";
import cbdCannabisConnect from "../assets/img/icons/Cannabis-Connection-CBD.png";

import sativaLifeFlower from "../assets/img/icons/LifeFlower-Sativa.jpg";
import indicaLifeFlower from "../assets/img/icons/LifeFlower-Indica.jpg";
import hybridLifeFlower from "../assets/img/icons/LifeFlower-Hybrid.jpg";

import sativaBudStop from "../assets/img/icons/BudStop-Sativa.png";
import indicaBudStop from "../assets/img/icons/BudStop-Indica.png";
import hybridBudStop from "../assets/img/icons/BudStop-Hybrid.png";

const iconSets = [
  { name: "none" },
  {
    name: "Heart Emojis",
    setType: "emoji",
    indica: "purple_heart",
    sativa: "heart",
    hybrid: "green_heart",
    sativaDom: "orange_heart",
    indicaDom: "blue_heart",
  },
  {
    name: "Heart Emojis no text",
    setType: "emoji",
    indica: "purple_heart",
    sativa: "heart",
    hybrid: "green_heart",
    sativaDom: "orange_heart",
    indicaDom: "blue_heart",
  },
  {
    name: "BudStop",
    indica: indicaBudStop,
    sativa: sativaBudStop,
    hybrid: hybridBudStop,
    sativaDom: hybridBudStop,
    indicaDom: hybridBudStop,
  },
  {
    name: "BudStop no text",
    indica: indicaBudStop,
    sativa: sativaBudStop,
    hybrid: hybridBudStop,
    sativaDom: hybridBudStop,
    indicaDom: hybridBudStop,
  },
  {
    name: "Cannabis Connection",
    indica: indicaCannabisConnect,
    sativa: sativaCannabisConnect,
    hybrid: hybridCannabisConnect,
    sativaDom: sativaHybridCannabisConnect,
    indicaDom: indicaHybridCannabisConnect,
    cbd: cbdCannabisConnect,
  },
  {
    name: "Cannabis Connection no text",
    indica: indicaCannabisConnect,
    sativa: sativaCannabisConnect,
    hybrid: hybridCannabisConnect,
    sativaDom: sativaHybridCannabisConnect,
    indicaDom: indicaHybridCannabisConnect,
    cbd: cbdCannabisConnect,
  },
  {
    name: "LifeFlower",
    indica: indicaLifeFlower,
    sativa: sativaLifeFlower,
    hybrid: hybridLifeFlower,
    sativaDom: hybridLifeFlower,
    indicaDom: hybridLifeFlower,
  },
  {
    name: "LifeFlower no text",
    indica: indicaLifeFlower,
    sativa: sativaLifeFlower,
    hybrid: hybridLifeFlower,
    sativaDom: hybridLifeFlower,
    indicaDom: hybridLifeFlower,
  },
  {
    name: "Circle Gradients",
    indica: indicaCG,
    sativa: sativaCG,
    hybrid: hybridCG,
    sativaDom: sativaDomCG,
    indicaDom: indicaDomCG,
  },
  // add " no text" to icon set name to remove text
  {
    name: "Circle Gradients no text",
    indica: indicaCG,
    sativa: sativaCG,
    hybrid: hybridCG,
    sativaDom: sativaDomCG,
    indicaDom: indicaDomCG,
  },
  {
    name: "Circle Flipped",
    indica: indicaCF,
    sativa: sativaCF,
    hybrid: hybridCF,
    sativaDom: sativaDomCF,
    indicaDom: indicaDomCF,
  },
  {
    name: "Circle Flipped no text",
    indica: indicaCF,
    sativa: sativaCF,
    hybrid: hybridCF,
    sativaDom: sativaDomCF,
    indicaDom: indicaDomCF,
  },
  {
    name: "Circle CMY",
    indica: indicaCMY,
    sativa: sativaCMY,
    hybrid: hybridCMY,
    sativaDom: sativaDomCMY,
    indicaDom: indicaDomCMY,
  },
  {
    name: "Circle CMY no text",
    indica: indicaCMY,
    sativa: sativaCMY,
    hybrid: hybridCMY,
    sativaDom: sativaDomCMY,
    indicaDom: indicaDomCMY,
  },
  {
    name: "Keystone",
    indica: indicaKeystone,
    sativa: sativaKeystone,
    hybrid: hybridKeystone,
    sativaDom: sativaKeystone,
    indicaDom: indicaKeystone,
    cbd: cbdKeystone,
    oneToOne: cbdKeystone,
  },
  {
    name: "Keystone no text",
    indica: indicaKeystone,
    sativa: sativaKeystone,
    hybrid: hybridKeystone,
    sativaDom: sativaKeystone,
    indicaDom: indicaKeystone,
    cbd: cbdKeystone,
  },
  {
    name: "Phenos-light",
    indica: indicaPhenos,
    sativa: sativaPhenos,
    hybrid: hybridPhenos,
    sativaDom: sativaDomPhenos,
    indicaDom: indicaDomPhenos,
    cbd: cbdPhenos,
    cbn: cbnPhenos,
    cbg: cbgPhenos,
    delta8: delta8Phenos,
  },
  {
    name: "Phenos-light no text",
    indica: indicaPhenos,
    sativa: sativaPhenos,
    hybrid: hybridPhenos,
    sativaDom: sativaDomPhenos,
    indicaDom: indicaDomPhenos,
    cbd: cbdPhenos,
    cbn: cbnPhenos,
    cbg: cbgPhenos,
    delta8: delta8Phenos,
  },
  {
    name: "Phenos-Part-Deux",
    indica: indicaPhenos2,
    sativa: sativaPhenos2,
    hybrid: hybridPhenos2,
    sativaDom: sativaDomPhenos2,
    indicaDom: indicaDomPhenos2,
    cbd: cbdPhenos,
    cbn: cbnPhenos,
    cbg: cbgPhenos,
    delta8: delta8Phenos,
  },
  {
    name: "Phenos-Part-Deux no text",
    indica: indicaPhenos2,
    sativa: sativaPhenos2,
    hybrid: hybridPhenos2,
    sativaDom: sativaDomPhenos2,
    indicaDom: indicaDomPhenos2,
    cbd: cbdPhenos,
    cbn: cbnPhenos,
    cbg: cbgPhenos,
    delta8: delta8Phenos,
  },
];

export default iconSets;
