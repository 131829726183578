import { Popover } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { ChromePicker } from "react-color";
import * as Styled from './style'

export const ColorPicker = ({ color, onChange, title }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [tempColor, setTempColor] = useState(color || "#fff");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Styled.Container>
            {title && <Styled.Title>{title}</Styled.Title>}
            <Styled.ButtonWrapper onClick={handleClick}>
                <Styled.Button bgcolor={color} />
            </Styled.ButtonWrapper>
            <Popover
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <ChromePicker
                    color={tempColor}
                    onChange={(c) => setTempColor(c.hex)}
                    onChangeComplete={onChange}
                />
            </Popover>
        </Styled.Container>
    );
};
